import React from 'react';
import { graphql } from 'gatsby';

import ProductGrid from '../components/ProductGrid';
import Seo from '../components/SEO';

function CategoryPage({ data: { category } }) {
  if (!category) return null;

  // const Group = category.products;

  // const dataResult = [];
  // for (let i = 0; i < Group.length; i++) {
  //   dataResult.push(Group[i].id);
  // }

  // if (typeof window !== 'undefined') {
  //   if (window.fbq != null) {
  //     window.fbq('track', 'ViewCategory', {
  //       content_name: category.name,
  //       content_ids: dataResult,
  //       content_type: 'product_group',
  //     });
  //   }
  // }

  return (
    <React.Fragment>
      <Seo pageTitle={category.name} />
      <h1
        className="p-4 mt-16 text-4xl font-bold text-white bg-primary md:text-4xl md:mt-20 lg:mt-12"
      >
        {category.name}
      </h1>

      <ProductGrid products={category.products} />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    category: graphCmsCategory(slug: { eq: $slug }) {
      name
      slug
      products {
        id
        name
        price
        organic
        inStock
        newIn
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        remoteId
      }
    }
  }
`;

export default CategoryPage;
